import React from 'react';
import Menu from '../components/Menu';
import MenuSection from '../components/Menu/MenuSection';
import MenuItem from '../components/Menu/MenuItem';
import Layout from '../layouts/layout';

const MenuVistaFoothill = () => (
	<Layout title="Menu - Vista Foothill">
		<div>
			<h1>Menu - Vista Foothill (temporarily closed)</h1>

			<p>Active military personnel enjoy an extra 10% off</p>

			<Menu>
				<MenuSection title="Smoked Meats">
					<MenuItem title="Beef Brisket" />
					<MenuItem title="Pulled Pork" />
					<MenuItem title="Pulled Chicken" />
					<MenuItem title="Beef Tri-tip (+$1)" />
					<MenuItem title="Signature Homemade Sausage" />
					<MenuItem title="Riblets" />
					<MenuItem title="Spare Ribs" />
					<MenuItem title="Beef Ribs" />
				</MenuSection>

				<MenuSection title="Housemade Sides">
					<MenuItem title="Coleslaw" />
					<MenuItem title="Potato Salad" />
					<MenuItem title="Smoked Corn" />
					<MenuItem title="BBQ Beans" />
					<MenuItem title="Mac & Cheese" />
					<MenuItem title="Macaroni Salad" />
					<MenuItem title="Pasta Salad" />
					<MenuItem title="Garlic Mashed Potatoes" />
				</MenuSection>

				<MenuSection
					title="Plates"
					description="choice of meat + side + hawaiian roll or bun | additional side 1.95"
				>
					<MenuItem
						title="One Meat Plate"
						description="tri-tip +$1"
						price="8.95"
					/>
					<MenuItem
						title="Two Meat Plate"
						description="tri-tip +$2"
						price="10.95"
					/>
					<MenuItem
						title="Three Meat Plate"
						description="tri-tip +$3"
						price="12.95"
					/>
					<MenuItem title="BBQ Sandwich Plate" price="12.95" />
					<MenuItem title="1/2 Slab Ribs Plate" price="16.95" />
					<MenuItem title="Full Slab Ribs Plate" price="26.95" />
				</MenuSection>

				<MenuSection
					title="Bundles"
					description="can substitute 1⁄2 slab ribs for 1 pound of meat | tri-tip add $3 per pound"
				>
					<MenuItem
						title="#1 (feeds 2-4)"
						description="1 pound meat + 2 large sides + 4 hawaiian rolls or buns + our famous peach cobbler"
						price="32.95"
					/>
					<MenuItem
						title="#2 (feeds 4-5)"
						description="2 pounds meat + 3 large sides + 6 hawaiian rolls or buns + our famous peach cobbler"
						price="49.95"
					/>
					<MenuItem
						title="#3 (feeds 6-8)"
						description="3 pounds meat + 3 large sides + 8 hawaiian rolls or buns + our famous peach cobbler"
						price="69.95"
					/>
				</MenuSection>

				<MenuSection
					title="breakfast"
					description="served from 6am-11am"
				>
					<MenuItem
						title="fully-loaded burritos"
						price="6.95"
						description={
							<p>
								all burritos come in a freshly-cooked tortilla
								and include our red or green salsa - made from
								scratch!
								<br />
								<br />
								#1 - housemade sausage, egg, potato, cheese, and
								beans
								<br />
								#2 - beef brisket, egg, potato, cheese, and
								beans
								<br />
								#3 - pulled chicken, egg, potato, cheese, and
								beans
								<br />
								#4 - pulled pork, egg, potato, cheese, and beans
							</p>
						}
					/>

					<MenuItem
						title="BBQ Breakfast Sandwich"
						description="with housemade sausage, egg, and cheese"
						price="5.95"
					/>
				</MenuSection>

				<MenuSection title="à la carte">
					<MenuItem
						title="meat per pound"
						description="(tri-tip +$3)"
						price="16.95"
					/>
					<MenuItem title="1/2 slab ribs" price="15.95" />
					<MenuItem title="full slab ribs" price="25.95" />
					<MenuItem
						title="small side"
						description="(feeds 1)"
						price="1.95"
					/>
					<MenuItem
						title="large side"
						description="(feeds 3-4)"
						price="4.95"
					/>
					<MenuItem
						title="BBQ sandwich"
						description="(tri-tip +$1 or coleslaw +$1)"
						price="6.95"
					/>
					<MenuItem
						title="fully loaded salad"
						description="choice of meat +$3"
						price="7.95"
					/>
					<MenuItem
						title="famous smoked peach cobbler"
						price="3.95"
					/>
					<MenuItem
						title="giant marshmallow krispy treat"
						price="1.95"
					/>
					<MenuItem title="freshly baked cookies (2)" price="1.95" />
				</MenuSection>

				<MenuSection
					title="kids"
					description="10 and under | includes juice box + side or applesauce"
				>
					<MenuItem title="BBQ Sandwich" price="5.95" />
					<MenuItem title="Mac & Cheese" price="4.95" />
				</MenuSection>

				<MenuSection title="drinks">
					<MenuItem title="small" price="1.39" />
					<MenuItem title="medium" price="1.59" />
					<MenuItem title="large" price="1.79" />
					<MenuItem title="x-large" price="1.99" />
				</MenuSection>
			</Menu>
		</div>
	</Layout>
);

export default MenuVistaFoothill;
